import { useNavigate } from 'react-router-dom';
import React, { useState, createContext, useContext, useEffect } from 'react'; 
import { motion } from 'framer-motion';
import Context from '../Context';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../style/notice.css';

const Notice = () => {
	const navigate = useNavigate();
	const {email, namesJson, selectedPartyID, RO} = useContext(Context);
	const [nameList, setNameList] = useState([]);
	const handleNotAttendingClick = (event) => {
		confirmAlert({
			title: 'Confirm Non Attendance',
			message: 'Are you sure that you are unable to attend?',
			buttons: [{
					label: 'Yes',
					onClick: async () => {
						try {
							const response = await fetch('/php/notAttending.php', {
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
								},
								body: JSON.stringify({ selectedPartyID: selectedPartyID, email: email })
							});
							if (!response.ok) {
								const errorData = await response.json();
								throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
							} else {
								navigate('/ThankYouNA');
							}
						} catch (error) {
							alert("Error submitting. Please try again later.");
						}
					}
				},
				{
					label: 'No'
				}
			]
		});
	};
	
	const handleAttendingClick = (event) => {
		navigate('/rsvp');
	};
	
    useEffect(() => {
		if (namesJson.length === 0) {
			navigate('/');
		} else {
			function getNamesArray(partyId) {
				return namesJson
					.filter(item => item.party === partyId)
					.map(item => item.name);
			}
			setNameList(getNamesArray(selectedPartyID));
		}
    }, [selectedPartyID]);

	return(
		<>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 1, ease: "easeInOut" }}
				className="home"
			>
				<div className="infoBox">
					<p>Dear</p>
						<p className="theNames">
						{nameList.map(name => (
							<h3 key={name}>{name}</h3>
						))}
					</p>
				</div>
				<div className="infoBox">
					<p>You are invited to celebrate the wedding of<br/>Laura and Karl </p>
					<p>Please join us for our special day.</p>
				</div>
				{RO === 0 && (
					<>
						<div className="infoBox">
							<h3><b>Ceremony</b></h3>
							<br/>
							<b>
								<a href="https://maps.app.goo.gl/r7s5JELKPxKdfrQd9" target="_blank">
									Register Office, Castle Hill, Winchester SO23 8UH
								</a><br/>
								15:00<br/>27th June 2025
							</b>
						</div>
					</>
				)}
				<div className="infoBox">
					<h3><b>Reception</b></h3>
					<br/>
					<b>
						<a href="https://maps.app.goo.gl/jhxYaTVp8g2cTP3L8" target="_blank">
						Fleming Park Bowling Club, Passfield Ave, Eastleigh SO50 9NL
						</a><br/>
						{RO === 1 ? '19:30' : '17:00'}<br/>27th June 2025
					</b>
				</div>
				<button id="rsvp" onClick={handleAttendingClick} className="noticeButtons">Attending</button><button id="rsvp" onClick={handleNotAttendingClick} className="noticeButtons">Not Attending</button>
			</motion.div>
		</>
	)
};

export default Notice;
