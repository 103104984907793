import React, { createContext, useContext } from 'react';
import { motion } from 'framer-motion';
import Context from '../Context';

function App() {
    const { setGoHome, selectedPartyID, setSelectedPartyID, RO } = useContext(Context);

    const isAndroid = /android/i.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
	setGoHome(true);
    const handleAddToCalendar = () => {
        const eventDetails = RO === 0 ? {
            title: 'Wedding of Laura and Karl',
            location: 'Register Office, Castle Hill, Winchester SO23 8UH',
            description: 'Wedding of Laura and Karl',
            startDate: '20250627T150000',
            endDate: '20250627T235959',
        } : {
            title: 'Wedding Reception of Laura and Karl',
            location: 'Fleming Park Bowling Club, Passfield Ave, Eastleigh SO50 9NL',
            description: 'Wedding Reception of Laura and Karl',
            startDate: '20250627T193000',
            endDate: '20250627T235959',
        };

        if (isIOS) {
            // Generate and download .ics file for iOS
            const icsContent = generateICSContent(eventDetails);
            const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${eventDetails.title}.ics`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up
        } else {
            // Generate Google Calendar link for other platforms
            const calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventDetails.title)}&details=${encodeURIComponent(eventDetails.description)}&location=${encodeURIComponent(eventDetails.location)}&dates=${eventDetails.startDate}/${eventDetails.endDate}`;
            window.open(calendarUrl);
        }
    };

    const generateICSContent = (eventDetails) => {
        const { title, description, location, startDate, endDate } = eventDetails;

        const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
UID:${Date.now()}@hton.uk
DTSTAMP:${new Date().toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z'}
DTSTART:${startDate.replace(/[-:]/g, '')}
DTEND:${endDate.replace(/[-:]/g, '')}
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`;
        return icsContent;
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "easeInOut" }}
        >
            <h1>Thank You!</h1>
            <br />
            {isAndroid && <button onClick={handleAddToCalendar}>Add to Android Calendar</button>}
            {isIOS && <button onClick={handleAddToCalendar}>Add to iPhone Calendar</button>}
            {!isAndroid && !isIOS && <button onClick={handleAddToCalendar}>Add to Calendar</button>}
        </motion.div>
    );
}

export default App;