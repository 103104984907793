import { useNavigate } from 'react-router-dom';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Context from '../Context';
import { confirmAlert } from 'react-confirm-alert';

const Rsvp = () => {
    const { goHome, setGoHome, email, setNamesJson, selectedPartyID, setSelectedPartyID } = useContext(Context);
    const navigate = useNavigate();
    const dietRef = useRef(null);
    const noteRef = useRef(null);
	
    useEffect(() => {
		if(goHome) {
			setGoHome(false);
			navigate('/');
		}
    }, [goHome]);
	
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            confirmAlert({
                title: 'Confirm Attendance',
                message: 'Ready to submit?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            try {
                                const response = await fetch('/php/attending.php', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        selectedPartyID: selectedPartyID,
                                        note: noteRef.current ? noteRef.current.value : '',
                                        diet: dietRef.current ? dietRef.current.value : '',
                                        email: email,
                                    }),
                                });
                                if (!response.ok) {
                                    const errorData = await response.json();
                                    throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
                                } else {
									setNamesJson('');
                                    navigate('/ThankYou');
                                }
                            } catch (error) {
                                alert('Error submitting. Please try again later.');
                            }
                        },
                    },
                    {
                        label: 'No',
                    },
                ],
            });
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
		<motion.form
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 1, ease: "easeInOut" }}
			onSubmit={handleSubmit}>
            <h1>RSVP</h1>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
                <textarea
                    className="attendees"
                    name="attendees"
                    placeholder="If someone from your party cannot attend, or you think we need to know anything else, please let us know"
                    ref={noteRef}
                />
                <textarea
                    className="dietReq"
                    name="diet"
                    placeholder="Please enter any dietary requirements"
                    ref={dietRef}
                />
            </motion.div>
            <div className="clearInputs">
                <button id="submit" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </motion.form>
    );
};

export default Rsvp;