import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { motion } from 'framer-motion';
import Context from '../Context';
import '../style/home.css';

const Home = () => {
    const navigate = useNavigate();
	const {setEmail, setLoading, setSelectedPartyID, namesJson, setNamesJson, setRO} = useContext(Context);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
	const emailInput = useRef(null);
	const codeInput1 = useRef(null);
	const codeInput2 = useRef(null);
	const codeInput3 = useRef(null);
	const codeInput4 = useRef(null);
	const codeInput5 = useRef(null);
	const codeInput6 = useRef(null);
	
    useEffect(() => {
        const fetchNames = async () => {
            try {
                const response = await fetch('/php/getAll.php');
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setNamesJson(data);
            } catch (error) {
                alert("Error fetching names. Please try again later.");
            }
        };
        fetchNames();
    }, []);

    const handleInputChange = (event) => {
        const term = event.target.value;
        setSearchTerm(term);

        if (term.length > 0) {
            const filteredNames = namesJson.filter((item) => {
                const lowerCaseName = item.name.toLowerCase();
                const lowerCaseTerm = term.toLowerCase();
                return lowerCaseName.includes(lowerCaseTerm);

            });
            setSuggestions(filteredNames);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = async (suggestion) => {
		try {
			setLoading(true);
			const response = await fetch('/php/getParty.php', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ selectedPartyID: suggestion.party })
			});
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
			} else {
				const data = await response.json();
				setSearchTerm(suggestion.name);
				setSuggestions([]);
				setSelectedPartyID(suggestion.party);
				setRO(data);
				navigate(`/Notice`);
			}
		} catch (error) {
			alert("Error fetching data. Please try again later.");
		}
		finally {
			setLoading(false);
		}
    };
	const handlePaste = (index) => (event) => {
		event.preventDefault();
		const pastedData = event.clipboardData.getData('text');
		if (/^\d{6}$/.test(pastedData)) {
			const codeArray = pastedData.split('');
			if (codeInput1.current) codeInput1.current.value = codeArray[0];
			if (codeInput2.current) codeInput2.current.value = codeArray[1];
			if (codeInput3.current) codeInput3.current.value = codeArray[2];
			if (codeInput4.current) codeInput4.current.value = codeArray[3];
			if (codeInput5.current) codeInput5.current.value = codeArray[4];
			if (codeInput6.current) codeInput6.current.value = codeArray[5];
			
            if (codeInput1.current) codeInput1.current.dispatchEvent(new Event('change'));
        
		}
	};
    const handleChange = (currentInput, nextInput) => (event) => {
        const value = event.target.value;
        if (value.length === 1 && nextInput && nextInput.current) {
            nextInput.current.focus();
        }

        if (
            codeInput1.current?.value &&
            codeInput2.current?.value &&
            codeInput3.current?.value &&
            codeInput4.current?.value &&
            codeInput5.current?.value &&
            codeInput6.current?.value
        ) {
            handleVerificationSubmit();
        }
    };

	const handleKeyDown = (currentInput, previousInput) => (event) => {
		if (event.key === 'Backspace' && !event.target.value && previousInput && previousInput.current) {
			previousInput.current.focus();
		}
	};
	
	const handleEmailSubmit = async (event) => {
		event.preventDefault();
		const email = emailInput.current.value;
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (emailPattern.test(email)) {
			try {
				const response = await fetch('/php/sendVerification.php', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ email:email }),
				});
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
				
				if (data.status === "success") {
					setEmail(email);
					setEmailSubmitted(true);
					emailInput.current.value = '';
				} else {
					alert('Failed to send verification email.');
				}
			} catch (error) {
				alert('Error sending verification email.');
			}
		} else {
			alert('Invalid email format.');
		}
	};

    const handleVerificationSubmit = async () => {
        const code =
            codeInput1.current.value +
            codeInput2.current.value +
            codeInput3.current.value +
            codeInput4.current.value +
            codeInput5.current.value +
            codeInput6.current.value;

        try {
            const response = await fetch('/php/verifyCode.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code }),
            });
            if (response.ok) {
                setEmailVerified(true);
                [codeInput1, codeInput2, codeInput3, codeInput4, codeInput5, codeInput6].forEach((input) => {
                    if (input.current) {
                        input.current.classList.remove('error');
                    }
                });
            } else {
                [codeInput1, codeInput2, codeInput3, codeInput4, codeInput5, codeInput6].forEach((input) => {
                    if (input.current) {
                        input.current.classList.add('error');
                    }
                });
                alert('Invalid verification code.');
            }
        } catch (error) {
            alert('Error verifying code.');
        }
    };
	
    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1, ease: "easeInOut" }}
                className="home"
            >
				{namesJson.length > 0 ? (
					<div className="partySearch">
					<h1>The Wedding</h1>
					<h3>of</h3>
					<h3>Laura and Karl</h3>
					<br/>
					{emailVerified ? (
						<>
							<input
								type="text"
								value={searchTerm}
								onChange={handleInputChange}
								placeholder="Enter name"
								className="name"
							/>
							{suggestions.length > 0 && (
								<ul className="suggestions">
									{suggestions.map((suggestion) => (
										<li key={suggestion.name} onClick={() => handleSuggestionClick(suggestion)}>
											{suggestion.name}
										</li>
									))}
								</ul>
							)}
						</>
					) : (
						<>
							{emailSubmitted ? (
								<>
									<p>A Verification code has been emailed to you.<br/>Please be sure to check your junk folder.</p>
									<p>Please enter your verification code:</p>
									<input
										type="text"
										placeholder="0"
										className="emailCode"
										ref={codeInput1}
										maxLength="1"
										onPaste={handlePaste(0)}
										onChange={handleChange(codeInput1, codeInput2)}
										onKeyDown={handleKeyDown(codeInput1, null)}
									/>
									<input
										type="text"
										placeholder="0"
										className="emailCode"
										ref={codeInput2}
										maxLength="1"
										onPaste={handlePaste(1)}
										onChange={handleChange(codeInput2, codeInput3)}
										onKeyDown={handleKeyDown(codeInput2, codeInput1)}
									/>
									<input
										type="text"
										placeholder="0"
										className="emailCode"
										ref={codeInput3}
										maxLength="1"
										onPaste={handlePaste(2)}
										onChange={handleChange(codeInput3, codeInput4)}
										onKeyDown={handleKeyDown(codeInput3, codeInput2)}
									/>
									<input
										type="text"
										placeholder="0"
										className="emailCode"
										ref={codeInput4}
										maxLength="1"
										onPaste={handlePaste(3)}
										onChange={handleChange(codeInput4, codeInput5)}
										onKeyDown={handleKeyDown(codeInput4, codeInput3)}
									/>
									<input
										type="text"
										placeholder="0"
										className="emailCode"
										ref={codeInput5}
										maxLength="1"
										onPaste={handlePaste(4)}
										onChange={handleChange(codeInput5, codeInput6)}
										onKeyDown={handleKeyDown(codeInput5, codeInput4)}
									/>
									<input
										type="text"
										placeholder="0"
										className="emailCode"
										ref={codeInput6}
										maxLength="1"
										onPaste={handlePaste(5)}
										onChange={handleChange(codeInput6, null)}
										onKeyDown={handleKeyDown(codeInput6, codeInput5)}
									/>
								</>
							) : (
								<>
									<input
										type="text"
										placeholder="Enter email"
										className="name"
										ref={emailInput}
									/>
									<button id="submit" onClick={handleEmailSubmit}>
										Submit
									</button>
								</>
							)}
						</>
					)}
					</div>
				) : (
					<>
						<h1>Thank You</h1>
						<p><center>Thank you to everyone for responding.</center></p>
					</>
				)}
            </motion.div>
        </>
    );
};

export default Home;