import { createContext, useState, useContext } from 'react';

const AppContext = createContext({
	namesJson: '', 
	setNamesJson: () => {},
});

export const AppProvider = ({ children }) => {
	const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
	const [goHome, setGoHome] = useState('');
	const [namesJson, setNamesJson] = useState('');
	const [selectedPartyID, setSelectedPartyID] = useState('0');
	const [RO, setRO] = useState('0');

	return (
		<AppContext.Provider value={{ loading, setLoading, email, setEmail, goHome, setGoHome, namesJson, setNamesJson, selectedPartyID, setSelectedPartyID, RO, setRO }}>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => {
	return useContext(AppContext);
};

export default AppContext;