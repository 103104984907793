import { useNavigate } from 'react-router-dom';
import React, { createContext, useContext } from 'react'; 
import { motion } from 'framer-motion';
import Context from '../Context';

function ThankYouNA() {
    const { setGoHome } = useContext(Context);
	setGoHome(true);
	return (
		<motion.div initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 1, ease: "easeInOut" }}
		>
			<h1>Thank You.</h1>
			<br/>
			<p>Thank you for letting us know. We're so sorry you won't be able to join us, but we appreciate you updating your RSVP.</p>
		</motion.div>
	);
}

export default ThankYouNA;