import { Outlet, Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState, createContext, useContext } from 'react'; 
import { motion, AnimatePresence } from 'framer-motion';
import Context from '../Context';

const Layout = () => {
	const {loading, selectedPartyID} = useContext(Context);

    const navigate = useNavigate();
    useEffect(() => {
		if (selectedPartyID === '0') {
			navigate('/');
		}
    }, [selectedPartyID]);
	
	return (
		<>
			{loading && ( <div class="loader"></div> )}
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, ease: "easeInOut" }}
				id="wrapper"
			>
				<div id="dynamicWrap">
					<Outlet /> 
				</div>
			</motion.div>
		</>
	);
};

export default Layout;